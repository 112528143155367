import { useEffect, useState } from "react";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import dateFormat from "dateformat";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Link,
} from "@react-pdf/renderer";
// Create styles
import logo from "../../images/avilash_engineering_corp_logo.jpg";
import React from "react";
import InvalidAccess from "./InvalidAccess";

let ttl = 0;
let tax = 0;
let i = 0;

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    color: "black",
  },
  Text: {
    width: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  bottom: {
    position: "absolute",
    fontSize: 7,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  address4:{
    width: "24%",
  },
  line: {
    width: "80%",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 3,
  },
  containerHead: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  container: {
    flexDirection: "row",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  containerBottom: {
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  toCapital:{
    textTransform:"capitalize",
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  logo: {
    width: 70,
    height: 70,
    marginLeft: 250,
  },
  customerName: {
    fontSize: 12,
    textAlign: "left",
    fontWeight: 900,
  },
  customerNameOther: {
    fontSize: 8,
    textAlign: "left",
    fontWeight: 900,
  },
  text: {
    fontSize: 8,
    textAlign: "center",
  },
  Srno: { width: "10%", fontSize: 9, fontWeight: 700, textAlign: "center" },
  Description: {
    width: "40%",
    fontSize: 9,
    fontWeight: 700,
    textAlign: "left",
    textTransform: "capitalize",
  },
  smallDescription: {
    fontSize: 7,
    fontWeight: 500,
    textAlign: "left",
    textTransform: "capitalize",
  },
  qty: { width: "5%", fontSize: 9, fontWeight: 700, textAlign: "center", },
  tax: { width: "8%", fontSize: 9, fontWeight: 700, textAlign: "center", },
  uprice: { width: "23%", fontSize: 9, fontWeight: 700, textAlign: "right" },
  make: { width: "18%", fontSize: 9, fontWeight: 700, textAlign: "right" },
  tprice: { width: "23%", fontSize: 9, fontWeight: 700, textAlign: "right" },
  gTotal: {
    borderBottomColor: "#000000",
    borderBottomWidth: 2,
    borderTopWidth: 1,
    borderTopColor: "#000000",
    width: "23%",
    fontSize: 11,
    fontWeight: 900,
    textAlign: "right",
  },
  date: {
    fontSize: 8,
    textAlign: "right",
  },


  // stylefor PI

  SrnoPI: { width: "10%", fontSize: 9, fontWeight: 700, textAlign: "center" },
  DescriptionPI: {
    width: "40%",
    fontSize: 9,
    fontWeight: 700,
    textAlign: "left",
    textTransform: "capitalize",
  },
topBarPI:
{
    flexDirection: "row",
    fontStyle: "bold",
    flexGrow: 1,
},
customerNameOtherPI: {
    fontSize: 8,
    textAlign: "left",
    fontWeight: 900,
    width:"50%",
    paddingRight:"50"
  },
BoldText:{
    fontSize:12,
    fontWeight: 900,
    textAlign: "right",
    width: "40%",
    backgroundColor: "#bff0fd",
    padding: 10,
  },
  whitePI:{
    width:"10%",
  },
  titlePI: {
    fontWeight: "bold",
    fontSize: 12,
    width: "50%",
    padding: 10,
    textAlign: "left",
    backgroundColor: "#bff0fd",
  },
smallDescriptionPI: {
    fontSize: 7,
    fontWeight: 500,
    textAlign: "",
    textTransform: "capitalize",
  },

smallDescriptionRPI: {
    fontSize: 7,
    fontWeight: 500,
    textAlign: "right",
    textTransform: "capitalize",
  },
  qtyPI: { width: "5%", fontSize: 9, fontWeight: 700, textAlign: "center", },
  DescriptionINVPI: { width: "35%", fontSize: 9, fontWeight: 700, textAlign: "left", },
  upricePI: { width: "15%", fontSize: 9, fontWeight: 700, textAlign: "right" },
  tpricePI: { width: "20%", fontSize: 9, fontWeight: 700, textAlign: "right" },
  gTotalPI: {
    borderBottomColor: "#000000",
    borderBottomWidth: 2,
    borderTopWidth: 1,
    borderTopColor: "#000000",
    width: "20%",
    fontSize: 11,
    fontWeight: 900,
    textAlign: "right",
  },

});

var converter = require('number-to-words');

// Create Document Component
function QuotePDF() {
  let totalamt = 0;
  const [pdfData, setPdfData] = useState({
    corporate: { business: "" },
    customer: {},
    quote: {},
    address: {},
    bank: {},
    salesPerson: {},
    products: [
      { product_name: "", sku: "", manufacture: "", qty: 0, price: 0, tax: 0 },
    ],
  });

  const getProduct = {
    corp_id: localStorage.getItem("business_id"),
    quote_id: localStorage.getItem("generatePDF"),
    user_id: localStorage.getItem("user"),
    module: "grabForPDF",
    product: "quotationB",
  };

  async function AxiosPDF(formData) {
    NotificationManager.info("please wait...");
    JSON.stringify(formData);
    axios({
      method: "post",
      url: "https://www.bestwebdealers.com/reactManager/",
      data: formData,
    }).then((res) => {
      if (res.data.data != undefined) {
        console.log(res.data.data[0]);
        setPdfData(res.data.data[0]);
      }
    });
  }

  useEffect(() => {
    AxiosPDF(getProduct);
  }, []);
  if (
    localStorage.getItem("user") == 20000 ||
    localStorage.getItem("user") == ""
  ) {
    return <InvalidAccess />;
  }
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  if(pdfData.quote.status==1){
  return (
    <>
      <PDFViewer
        author={"Best Web Dealers (https://www.bestwebdealers.com)`"}
        creator={"Harleen Grover"}
        creator={"Best Web Dealers"}
        style={styles.viewer}
      >
        {/* Start of the document*/}
        <Document>
          {/*render a single page*/}
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <Image src={logo} style={styles.logo} />
              <Text style={styles.title}>{pdfData.corporate.business}</Text>
              <Text>{"\n"}</Text>
              <Text style={styles.date}>
                Date : {dateFormat(pdfData.quote.date_entered, "dd mmm, yyyy")}
              </Text>

              <Text style={styles.customerName}>
                To, {pdfData.customer.company}
              </Text>
              <Text style={styles.customerNameOther}>
                {pdfData.customer.street + ", " + pdfData.customer.city}
              </Text>
              <Text style={styles.customerNameOther}>
                Phone: {pdfData.customer.number}
              </Text>
              <Text style={styles.customerNameOther}>
                Email: {pdfData.customer.email}
              </Text>
              <Text>&nbsp;</Text>

              <View style={styles.containerHead}>
                <Text style={styles.Srno}>Sr. No.</Text>
                <Text style={styles.Description}>DESCRIPTION</Text>
                <Text style={styles.qty}>QTY</Text>
                <Text style={styles.tax}>TAX</Text>
                <Text style={styles.make}>MAKE</Text>
                <Text style={styles.uprice}>UNIT PRICE </Text>
                <Text style={styles.tprice}>TOTAL PRICE</Text>
              </View>

              {pdfData.products.map((pros, index) => {
                const prott =(pros.qty * pros.price);
                ttl+=prott;
                if(pdfData.quote.withtax==1){
                  tax+= (prott*pros.tax)/ 100;
                }
                const proddescription = pros.pro_description
                  ? " (" + pros.pro_description + ")"
                  : "";
                return (
                  <View key={index} style={styles.container}>
                    <Text style={styles.Srno}>{index + 1}</Text>
                    <Text style={styles.Description}>
                      {pros.product_name+ ' - '} <Text style={styles.smallDescription}>{pros.sku}</Text>
                      <Text style={styles.smallDescription}>
                        {proddescription}
                      </Text>
                    </Text>
                    <Text style={styles.qty}>{pros.qty}</Text>
                    <Text style={styles.tax}>{pros.tax}%</Text>
                    <Text style={styles.make}>{pros.manufacture}</Text>
                    <Text style={styles.uprice}> {pros.price}</Text>
                    <Text style={styles.tprice}>
                      {prott}
                    </Text>
                  </View>
                );
              })
              
              }
              <View style={styles.containerBottom}>
                <Text style={styles.Srno}></Text>
                <Text style={styles.Description}></Text>
                <Text style={styles.qty}></Text>
                <Text style={styles.qty}></Text>
                <Text style={styles.make}></Text>
                <Text style={styles.upricePI}>Total</Text>
                <Text style={styles.tprice}>{ttl.toFixed(2)}</Text>
              </View>
              {pdfData.quote.withtax==1 ? <View style={styles.containerBottom}>
                <Text style={styles.SrnoPI}></Text>
                <Text style={styles.Description}></Text>
                <Text style={styles.qty}></Text>
                <Text style={styles.qty}></Text>
                <Text style={styles.make}></Text>
                <Text style={styles.upricePI}>Gst Tax</Text>
                <Text style={styles.qtyPI}></Text>
                <Text style={styles.tpricePI}>
                  {tax.toFixed(2)}
                </Text>
              </View> : <></> }
              <View style={styles.containerBottom}>
                <Text style={styles.Srno}></Text>
                <Text style={styles.Description}></Text>
                <Text style={styles.qty}></Text>
                <Text style={styles.qty}></Text>
                <Text style={styles.make}></Text>
                <Text style={styles.uprice}>Grand Total</Text>
                <Text style={styles.gTotal}>
                  {(ttl+tax).toFixed(0)}
                </Text>
              </View>
              <View>
                <Text style={styles.Srno}></Text>
                <Text style={styles.Description}>
                  TERMS AND CONDITIONS{"\n"}
                  {pdfData.quote.comment}
                </Text>
                <Text style={styles.qty}></Text>
                <Text style={styles.uprice}></Text>
                <Text style={styles.tprice}></Text>
              </View>
              <View style={styles.containerBottom}>
                <Text style={styles.Srno}></Text>
                <Text style={styles.Description}></Text>
                <Text style={styles.qty}></Text>
                <Text style={styles.qty}></Text>
                <Text style={styles.make}></Text>
                <Text style={styles.uprice}></Text>
                <Text style={styles.tprice}></Text>
              </View>
              <View style={styles.containerBottom}>
                <Text style={styles.Srno}></Text>
                <Text style={styles.Description}></Text>
                <Text style={styles.qty}></Text>
                <Text style={styles.qty}></Text>
                <Text style={styles.make}></Text>
                <Text style={styles.uprice}></Text>
                <Text style={styles.tprice}></Text>
              </View>
              <View style={styles.containerBottom}>
                <Text style={styles.Description}>
                  With Regards,{"\n"}
                  {pdfData.corporate.business}
                </Text>
                <Text style={styles.qty}></Text>
                <Text style={styles.uprice}></Text>
                <Text style={styles.tprice}></Text>
              </View>
              <View style={styles.containerBottom}>
                <Text style={styles.Description}>
                  {pdfData.salesPerson.name}{"\n"}{pdfData.salesPerson.designation}
                  {"\n"}
                  {pdfData.salesPerson.phone}
                </Text>
                <Text style={styles.qty}></Text>
                <Text style={styles.uprice}></Text>
                <Text style={styles.tprice}></Text>
              </View>
              <Text></Text>
              <Text></Text>
              <Text></Text>
              <Text></Text>
              <Text style={styles.Description}>
                *This Quotation is valid for 15 days only.*
              </Text>
            </View>
            <View>
              <Text>{"\n"}</Text>
            </View>
            <View>
              <Text>{"\n"}</Text>
            </View>
            <View style={styles.container}></View>
            <View fixed>
              <Text style={styles.text}>{pdfData.corporate.address}</Text>
              <Text style={styles.text}>{pdfData.corporate.phone}</Text>
              <Text>&nbsp;</Text>

              <Text>&nbsp;</Text>
              <Text style={styles.bottom} fixed>
                This Quotation is autogenerated by QuotationBuilder{"\n"}
                Designed and Developed for{" "}
                <Link href="https://avilashcorp.com/">
                  {pdfData.corporate.business}
                </Link>{" "}
                with LOVE by{" "}
                <Link href="https://www.bestwebdealers.com">
                  Best Web Dealers
                </Link>
              </Text>
            </View>
          </Page>
          {/* <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text>World</Text>
          </View>
        </Page> */}
        </Document>
      </PDFViewer>
    </>
  );
}else if(pdfData.quote.status==2){
  return (
    <>
      <PDFViewer
        author={"Best Web Dealers (https://www.bestwebdealers.com)`"}
        creator={"Harleen Grover - Best Web Dealers"}
        style={styles.viewer}
      >
        {/* Start of the document*/}
        <Document>
          {/*render a single page*/}
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <Image src={logo} style={styles.logo} />
              <View style={styles.topBarPI}>
                <Text style={styles.titlePI}>
                  {pdfData.corporate.business}
                  {'\n'}
                  <Text style={styles.smallDescriptionPI}>
                    {pdfData.corporate.address}
                    {'\n\n'}
                  Phone: {pdfData.corporate.phone} {'\n'}
                  Email: {pdfData.corporate.email}{'\n'}
                  Website: {pdfData.corporate.website}{'\n'}
                  GST No.: {pdfData.corporate.gst}
                  </Text>
                </Text>
                <Text style={styles.whitePI}></Text>
                <Text style={styles.BoldText}>PROFORMA INVOICE
                  <Text style={styles.smallDescriptionRPI}>{'\n\n'}
                  Date: {dateFormat(pdfData.quote.date_entered, "dd mmm, yyyy")}{'\n'}
                  {/* {dateFormat(new Date(pdfData.quote.date_entered.valueOf() + 15), "dd mmm, yyyy")} */}
                  Customer ID: {'CS'+pdfData.customer.id}{'\n'}{'\n'}{'\n'}{'\n'}
                  NOTE: This PI is valid for 15 days only.
                  </Text>
                </Text>
              </View>
              <Text>{"\n"}</Text>

              <View style={styles.topBarPI}>
              
              <Text style={styles.customerNameOtherPI}>
                <Text style={styles.customerName}>
                  CUSTOMER  {'\n\n'}
                </Text>
                Company: {pdfData.customer.company+'\n'}
                Address: {pdfData.customer.street + ", " + pdfData.customer.city}{'\n'}
                Phone: {pdfData.customer.number}{'\n'}
                Email: {pdfData.customer.email}{'\n'}
              </Text>

              <Text style={styles.customerNameOtherPI}>
                <Text style={styles.customerName}>
                  SHIPPING ADDRESS{'\n\n'}
                </Text>
                Company: {pdfData.customer.company+'\n'}
                Address: {pdfData.customer.street + ", " + pdfData.customer.city}{'\n'}
                Phone: {pdfData.customer.number}{'\n'}
                Email: {pdfData.customer.email}{'\n'}
              </Text>

              </View>
              <Text>&nbsp;</Text>

              <View style={styles.containerHead}>
                <Text style={styles.SrnoPI}>PART NO.</Text>
                <Text style={styles.Description}>DESCRIPTION</Text>
                <Text style={styles.tax}>Tax</Text>
                <Text style={styles.qtyPI}>MAKE</Text>
                <Text style={styles.qtyPI}>QTY</Text>
                <Text style={styles.upricePI}>UNIT PRICE </Text>
                <Text style={styles.tpricePI}>TOTAL PRICE</Text>
              </View>

              {pdfData.products.map((pros, index) => {
                const prott =(pros.qty * pros.price);
                ttl+=prott;
                if(pdfData.quote.withtax==1){
                  tax+= (prott*pros.tax)/ 100;
                }
                const proddescription = pros.pro_description
                  ? "(" + pros.pro_description + ")"
                  : "";
                return (
                  <View key={index} style={styles.container}>
                    <Text style={styles.SrnoPI}>{index + 1}</Text>
                    <Text style={styles.Description}>
                      {pros.product_name} - {pros.sku}{" "}
                      <Text style={styles.smallDescriptionPI}>
                        {proddescription}
                      </Text>
                    </Text>
                    <Text style={styles.tax}>{pros.tax}%</Text>
                    <Text style={styles.qtyPI}>{pros.manufacture}</Text>
                    <Text style={styles.qtyPI}>{pros.qty}</Text>
                    <Text style={styles.upricePI}> {pros.price}</Text>
                    <Text style={styles.tpricePI}>
                      {(prott).toFixed(2)}
                    </Text>
                  </View>
                );
              })}
              <View style={styles.containerBottom}>
                <Text style={styles.SrnoPI}></Text>
                <Text style={styles.DescriptionINVPI}></Text>
                <Text style={styles.qtyPI}></Text>
                <Text style={styles.qtyPI}></Text>
                <Text style={styles.tax}></Text>
                    <Text style={styles.qtyPI}></Text>
                <Text style={styles.upricePI}>Total </Text>
                <Text style={styles.qtyPI}></Text>
                <Text style={styles.tpricePI}>{ttl.toFixed(2)}</Text>
              </View>
              {pdfData.quote.withtax==1 ? <View style={styles.containerBottom}>
                <Text style={styles.SrnoPI}></Text>
                <Text style={styles.DescriptionINVPI}></Text>
                <Text style={styles.qtyPI}></Text>
                <Text style={styles.qtyPI}></Text>
                    <Text style={styles.tax}></Text>
                    <Text style={styles.qtyPI}></Text>
                <Text style={styles.upricePI}>Gst Tax</Text>
                <Text style={styles.qtyPI}></Text>
                <Text style={styles.tpricePI}>
                  {(tax).toFixed(2)}
                </Text>
              </View> : <></> }
              
              <View style={styles.containerBottom}>
                <Text style={styles.SrnoPI}></Text>
                <Text style={styles.DescriptionINVPI}></Text>
                <Text style={styles.qty}></Text>
                <Text style={styles.qtyPI}></Text>
                <Text style={styles.tax}></Text>
                    <Text style={styles.qtyPI}></Text>
                <Text style={styles.upricePI}>Grand Total</Text>
                <Text style={styles.qtyPI}></Text>
                <Text style={styles.gTotalPI}>
                  {(ttl+tax).toFixed(0)+'.00'}
                </Text>
              </View>
              <View>
                <Text style={styles.SrnoPI}></Text>
                <Text style={styles.DescriptionINVPI}>
                  TERMS AND CONDITIONS{"\n"}
                  {pdfData.quote.comment}
                </Text>
                <Text style={styles.qtyPI}></Text>
                <Text style={styles.upricePI}></Text>
                <Text style={styles.tpricePI}></Text>
              </View>
              <View style={styles.containerHead}>
                <Text style={styles.DescriptionINVPI}>ADDITIONAL DETAILS</Text>
              </View>
              <View style={styles.containerBottom}>
                <Text style={styles.SrnoPI}></Text>
                <Text style={styles.customerNameOther}>
                    {pdfData.bank.map((bnks, index) => {
                      return bnks.branch+ "\n" +bnks.Detail +"\n\n";
                    })}
                </Text>
              </View>
              <View style={styles.containerBottomPI}>
                <Text style={styles.SrnoPI}>{'\n'}</Text>
              </View>
              <View style={styles.containerBottomPI}>
                <Text style={styles.customerNameOther}>AMOUNT IN WORDS:--  
                  <Text style={styles.toCapital}>
                    {'\n' + converter.toWords((ttl+tax).toFixed(0))} only
                  </Text>
                </Text>
              </View>
              <View style={styles.containerBottomPI}>
                <Text style={styles.customerNameOther}>I certify the above to be true and correct to the best of my knowledge.</Text>
              </View>
              <View style={styles.containerBottom}>
                <Text style={styles.qtyPI}></Text>
                <Text style={styles.qtyPI}></Text>
                <Text style={styles.upricePI}></Text>
                <Text style={styles.qtyPI}></Text>
                <Text style={styles.tpricePI}></Text>
              </View>
              <View style={styles.containerBottom}>
                <Text style={styles.DescriptionINVPI}>
                  {pdfData.salesPerson.name} {"\n"}{pdfData.salesPerson.designation},
                  {"\n"}
                  {pdfData.salesPerson.phone}
                </Text>
                <Text style={styles.qtyPI}></Text>
                <Text style={styles.qtyPI}></Text>
                <Text style={styles.uprice}></Text>
                <Text style={styles.qtyPI}></Text>
                <Text style={styles.tprice}></Text>
              </View>
              
              <View style={styles.containerBottom}>
                <Text style={styles.DescriptionINVPI}>
                  For {pdfData.corporate.business}
                  
                </Text>
                <Text style={styles.qtyPI}></Text>
                <Text style={styles.qtyPI}></Text>
                <Text style={styles.uprice}></Text>
                <Text style={styles.qtyPI}></Text>
                <Text style={styles.tprice}></Text>
              </View>                
              <Text></Text>
              <Text></Text>
              <Text></Text>
              <Text></Text>
              <Text style={styles.Description}>
                
              </Text>
            </View>
            <View>
              <Text>{"\n"}</Text>
            </View>
            <View>
              <Text>{"\n"}</Text>
            </View>
            <View style={styles.container}></View>
            <View fixed>
              {pdfData.addresses.map((addres, index) => {
                return <Text style={styles.text}>{addres.office+": "+addres.address}</Text>
              })}
              <Text>&nbsp;</Text>

              <Text>&nbsp;</Text>
              <Text style={styles.bottom} fixed>
                This Quotation is autogenerated by QuotationBuilder{"\n"}
                Designed and Developed for{" "}
                <Link href="https://avilashcorp.com/">
                  {pdfData.corporate.business}
                </Link>{" "}
                with LOVE by{" "}
                <Link href="https://www.bestwebdealers.com">
                  Best Web Dealers
                </Link>
              </Text>
            </View>
          </Page>
          {/* <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text>World</Text>
          </View>
        </Page> */}
        </Document>
      </PDFViewer>
    </>
  );
}
}
export default QuotePDF;
